
body {
  margin: 0;
  background-color: #fafafa;
}

#root {
  /* display: flex; */
}

@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.9/dist/web/static/pretendard.min.css");